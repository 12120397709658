/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        var winW = $(window).innerWidth();
        var winH = $(window).innerHeight();
        var mobileWidth = 1024;

        // Mobile heights
        if(winW <= mobileWidth) {
          $('.main .hero').css({ height : winH });
          $('.say-hello .main .hero').css({ minHeight : '100vh', height : 'auto' });
        } else if(winW > mobileWidth) {
          $('.main .hero').css({ height : '100vh' });
          $('.say-hello .main .hero').css({ minHeight : '100vh', height : 'auto' });
        }


        //
        //
        // Copy link to clipboard
          $('.wp-block-button.copy a').each(function() {
            $(this).on('click', function(){
              var $temp = $('<input>');
              $('.wp-block-button.copy a').removeClass('clicked');
              $(this).addClass('clicked');
              $('body').append($temp);
              $temp.val($(this).text()).select();
              document.execCommand("copy");
              $temp.remove();
            });
          });

          $('.middle-part.copy > a').each(function() {
            $(this).on('click', function(){
              var $temp = $('<input>');
              $('.middle-part.copy > a').removeClass('clicked');
              $(this).addClass('clicked');
              $('body').append($temp);
              $temp.val($(this).text()).select();
              document.execCommand("copy");
              $temp.remove();
            });
          });
        //
        //
        //



        //
        //
        // Animations
        // $(window).load(function(){

          $('.lottie').each(function() {
            $(this).parent('.wp-block-column').addClass('has-lottie');
          });


          $('.wp-block-columns').each(function() {

            $(this).waypoint(function(direction) {
              if (direction === 'down') {
                $(this).find('.wp-block-column:not(.has-lottie)').addClass('animated-col');
                console.log('down');
              }
            }, {
              offset: '90%'
            });

            $(this).waypoint(function(direction) {
              if (direction === 'up') {
                console.log('up');
                $(this).find('.wp-block-column:not(.has-lottie)').removeClass('animated-col');
              }
            }, {
              offset: '90%'
            });

          });


          $('.friends .wp-block-media-text').each(function() {

            $(this).waypoint(function(direction) {
              if (direction === 'down') {
                $(this).addClass('animated-col');
                console.log('down');
              }
            }, {
              offset: '90%'
            });

            $(this).waypoint(function(direction) {
              if (direction === 'up') {
                console.log('up');
                $(this).removeClass('animated-col');
              }
            }, {
              offset: '90%'
            });

          });


          $('.lottie').each(function() {

            $(this).waypoint(function(direction) {
              if (direction === 'down') {
                $(this).addClass('animated-lottie');
                $(this).find('lottie-player').get(0).play();
                console.log('down');
              }
            }, {
              offset: '100%'
            });

            $(this).waypoint(function(direction) {
              if (direction === 'up') {
                console.log('up');
                $(this).removeClass('animated-lottie');
                $(this).find('lottie-player').get(0).pause();
              }
            }, {
              offset: '100%'
            });

          });


          $('footer .footer-layout > div').each(function (i) {

            $(this).waypoint(function(direction) {
              if (direction === 'down') {
                $(this).addClass('animated-lottie');
                console.log('down');
              }
            }, {
              offset: '90%'
            });

            $(this).waypoint(function(direction) {
              if (direction === 'up') {
                console.log('up');
                $(this).removeClass('animated-lottie');
              }
            }, {
              offset: '90%'
            });

            var $item = $(this);
            setTimeout(function() {
              $item.delay(900).animate({"opacity": 1}, 1000);
            }, 500*i);

          });

        // });



        //
        //
        //




        // Navigation

        //-- Main Menu
        $('.nav-icon').on('click', function(){
          $('.nav-icon').toggleClass('open');
          $('#nav-icon').toggleClass('open');
          $('.mobile-menu').toggleClass('open');
          if($('.nav-icon').hasClass('open')) {
            //  Add Classes
              $('header').addClass('open');
              $('body').css({ overflowY: 'hidden' });
          } else {
            //  Remove Classes
              $('header').removeClass('open');
              $('body').css({ overflowY: 'auto' });
          }
        });

        // Add down arrow to Hero panel
        $('.main > .hero').append('<div class="down-arrow"><div class="arrow"></div></div>');
        $(".down-arrow").on('click', function() {
          $('html, body').animate({
              scrollTop: $(".hero").next().offset().top
          }, 1000);
        });


        // Case Study Projects
        $('.case-study-slider').find('.carousel-item').first().addClass('active');
        function normalizeSlideHeights() {
            $('.carousel').each(function(){
              var items = $('.carousel-item', this);
              // reset the height
              items.css('min-height', 0);
              // set the height
              var maxHeight = Math.max.apply(null, 
                  items.map(function(){
                      return $(this).outerHeight();}).get() );
              items.css('min-height', maxHeight + 'px');
            });
        }
        normalizeSlideHeights();


        // Equal Heights
        $('.friends .wp-block-media-text').matchHeight();



        // //-- Slider
        // $('.case-study-sliders').slick({
        //   accessibility: true,
        //   slidesToShow: 1,
        //   slidesToScroll: 1,
        //   autoplay: true,
        //   autoplaySpeed: 6000,
        //   pauseOnHover: false,
        //   dots: false,
        //   prevArrow: '<div class="slick-button slick-prev"></div>',
        //   nextArrow: '<div class="slick-button slick-next"></div>'
        // });




        // GRAPHICS

        // Get all SVG's images, add an svg class to them,
        // and process them to SVG code with the script below
        // $('img[src$=".svg"]').each(function(){
        //   $(this).addClass('svg');
        // });

        // -- Change images with svg class to svg
        jQuery('img.svg').each(function(){
          var $img = jQuery(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');
              jQuery.get(imgURL, function(data) {
                  // Get the SVG tag, ignore the rest
                  var $svg = jQuery(data).find('svg');
                  // Add replaced image's ID to the new SVG
                  if(typeof imgID !== 'undefined') {
                      $svg = $svg.attr('id', imgID);
                  }
                  // Add replaced image's classes to the new SVG
                  if(typeof imgClass !== 'undefined') {
                      $svg = $svg.attr('class', imgClass+' replaced-svg');
                  }
                  // Remove any invalid XML tags as per http://validator.w3.org
                  $svg = $svg.removeAttr('xmlns:a');
                  // Replace image with new SVG
                  $img.replaceWith($svg);
              }, 'xml');
        });



        // Delay loading items then do things
        $(document).ready(function(){

          // Main nav header
          $('header.banner').delay(900).fadeIn(1400);

          // HERO
          // $('.hero .lottie').delay(0).css({ opacity : '1' });

          //-- Content
          // $('.home .hero .wp-block-group').delay(600).css({ opacity : '1' });
          $('.hero.wp-block-cover h2, .hero.wp-block-cover h3, .hero.wp-block-cover p, .hero.wp-block-cover .wp-block-image').delay(1800).css({ opacity : '1', top: '0' });
          $('.hero.wp-block-cover img').delay(900).css({ opacity : '1' });

          //-- Down arrow
          $('.hero .down-arrow').delay(900).fadeIn(1400);

          // Services
          $('body.services .hero .wp-block-image').delay(900).animate({"opacity": 1}, 1000);

          // Say Hello
          $('body.say-hello .hero .wp-block-button').each(function (i) {
            // store the item around for use in the 'timeout' function
            var $item = $(this);
            // execute this function sometime later:
            setTimeout(function() {
              $item.delay(900).animate({"opacity": 1}, 1000);
            }, 500*i);
            // each element should animate half a second after the last one.
          });

        });


        // REFRESH
        var _refresh = function () {

          var winW = $(window).innerWidth();
          var winH = $(window).innerHeight();
          var mobileWidth = 1024;

          // Mobile heights
          if(winW <= mobileWidth) {
            $('.main .hero').css({ height : winH });
            $('.say-hello .main .hero').css({ minHeight : '100vh', height : 'auto' });
          } else if(winW > mobileWidth) {
            $('.main .hero').css({ height : '100vh' });
            $('.say-hello .main .hero').css({ minHeight : '100vh', height : 'auto' });
          }

          normalizeSlideHeights();

        };
        $(window).on('resize', _refresh);
        $(document).ready(_refresh);
        $(window).on('load', _refresh);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
